var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c(
        "CCard",
        { staticClass: "filters", attrs: { "accent-color": "warning" } },
        [
          _c(
            "CCardHeader",
            {
              staticClass: "text-warning shadow-none card-header",
              attrs: { role: "button" },
              on: { click: _vm.resetFilters },
            },
            [
              _c(
                "strong",
                { staticClass: "m-0" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-filter${
                        _vm.$store.state.filterShow ? "-x" : ""
                      }`,
                    },
                  }),
                  _vm._v(" Filters"),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "card-header-actions" },
                [
                  _c("CIcon", {
                    attrs: {
                      name: `cil-chevron-${
                        _vm.$store.state.filterShow ? "bottom" : "top"
                      }`,
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "CCollapse",
            { attrs: { show: _vm.$store.state.filterShow } },
            [
              _c("CCardBody", { staticClass: "p-2" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-lg-3 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vertical-center flex-gap-1" },
                      [
                        _c("CSwitch", {
                          attrs: {
                            shape: "pill",
                            variant: "opposite",
                            color: "info",
                            checked: _vm.activeOnly,
                          },
                          on: {
                            "update:checked": (value) => {
                              _vm.activeOnly = value
                              _vm.inputFilter()
                            },
                          },
                        }),
                        _c("span", { staticClass: "label" }, [
                          _vm._v("Active Orders Only"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-lg-3 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vertical-center flex-gap-1" },
                      [
                        _c("CSwitch", {
                          attrs: {
                            shape: "pill",
                            variant: "opposite",
                            color: "info",
                            checked: _vm.uninvoicedOnly,
                          },
                          on: {
                            "update:checked": (value) => {
                              _vm.uninvoicedOnly = value
                              _vm.inputFilter()
                            },
                          },
                        }),
                        _c("span", { staticClass: "label" }, [
                          _vm._v("Uninvoiced Orders"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "col-lg-3 mb-2" }, [
                    _c(
                      "div",
                      { staticClass: "vertical-center flex-gap-1" },
                      [
                        _c("CSwitch", {
                          attrs: {
                            shape: "pill",
                            variant: "opposite",
                            color: "info",
                            checked: _vm.restaurantOnly,
                          },
                          on: {
                            "update:checked": (value) => {
                              _vm.restaurantOnly = value
                              _vm.inputFilter()
                            },
                          },
                        }),
                        _c("span", { staticClass: "label" }, [
                          _vm._v("Restaurant Orders"),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results available!",
            noItems: "No orders found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:sorter-value": _vm.sorterValue,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  [
                    item.is_editable
                      ? _c(
                          "CDropdown",
                          {
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "toggler",
                                  fn: function () {
                                    return [
                                      _c(
                                        "CButton",
                                        {
                                          staticClass: "p-0",
                                          attrs: { color: "ghost", size: "sm" },
                                        },
                                        [
                                          _c("CIcon", {
                                            attrs: { name: "cil-options" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "CDropdownItem",
                              {
                                attrs: {
                                  to: {
                                    name: "Edit Merchandise Order",
                                    params: { id: item.id },
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-2",
                                  attrs: { name: "cil-pencil" },
                                }),
                                _vm._v(" Edit Order "),
                              ],
                              1
                            ),
                            !item.canceled_at
                              ? _c(
                                  "CDropdownItem",
                                  {
                                    on: {
                                      click: function ($event) {
                                        return _vm.cancelOrder(item)
                                      },
                                    },
                                  },
                                  [
                                    _c("CIcon", {
                                      staticClass: "mr-2",
                                      attrs: { name: "cil-x-circle" },
                                    }),
                                    _vm._v(" Cancel Order "),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "CDropdownItem",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteOrder(item)
                                  },
                                },
                              },
                              [
                                _c("CIcon", {
                                  staticClass: "mr-2",
                                  attrs: { name: "cil-trash" },
                                }),
                                _vm._v(" Delete "),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "id",
            fn: function ({ item }) {
              return [_c("td", [_vm._v(" #" + _vm._s(item.id) + " ")])]
            },
          },
          {
            key: "merchant",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _c("div", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      _vm._s(
                        item.merchant?.restaurant_name ||
                          item.merchant?.first_name
                      )
                    ),
                  ]),
                  _c("div", { staticClass: "small text-muted" }, [
                    _vm._v("ID: " + _vm._s(item.merchant?.id)),
                  ]),
                ]),
              ]
            },
          },
          {
            key: "order_date",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm.moment(item.order_date).format("LL")) + " "
                  ),
                ]),
              ]
            },
          },
          {
            key: "status",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    item.canceled_at && item.cancellation_reason
                      ? _c(
                          "CBadge",
                          {
                            directives: [
                              {
                                name: "c-tooltip",
                                rawName: "v-c-tooltip",
                                value: {
                                  content: item.cancellation_reason,
                                  placement: "top",
                                },
                                expression:
                                  "{ content: item.cancellation_reason, placement: 'top' }",
                              },
                            ],
                            attrs: { color: "danger" },
                          },
                          [_vm._v(" " + _vm._s(_vm.getStatusText(item)) + " ")]
                        )
                      : _c(
                          "CBadge",
                          { attrs: { color: _vm.getStatusColor(item) } },
                          [_vm._v(" " + _vm._s(_vm.getStatusText(item)) + " ")]
                        ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "amount",
            fn: function ({ item }) {
              return [
                _c("td", [
                  _vm._v(
                    " " + _vm._s(_vm._f("toCurrency")(item.total_amount)) + " "
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _c(
        "CCard",
        { staticClass: "actions sticky-bottom" },
        [
          _c("CCardBody", { staticClass: "p-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex flex-wrap align-items-center",
                staticStyle: { gap: "0.75rem" },
              },
              [
                _c("div", [
                  _c("h5", { staticClass: "mt-1" }, [
                    _vm._v("Total: "),
                    _c(
                      "span",
                      { staticClass: "d-inline count bg-primary pb-1" },
                      [_vm._v(_vm._s(_vm.total))]
                    ),
                  ]),
                ]),
                _vm.pages > 1
                  ? _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("CPagination", {
                          attrs: {
                            align: "center",
                            dots: false,
                            pages: _vm.pages,
                            "active-page": _vm.activePage,
                          },
                          on: {
                            "update:activePage": function ($event) {
                              _vm.activePage = $event
                            },
                            "update:active-page": function ($event) {
                              _vm.activePage = $event
                            },
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
        ],
        1
      ),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.8 },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }