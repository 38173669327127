var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { col: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-user" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _vm._v(_vm._s(this.first_name)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _c(
                        "CButton",
                        { attrs: { color: "dark", to: this.prevRoute } },
                        [
                          _c("CIcon", {
                            staticClass: "align-bottom",
                            attrs: { name: "cil-arrow-thick-left" },
                          }),
                          _vm._v(" Back "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.loading || !_vm.user
                ? _c(
                    "CCardBody",
                    [_c("CSpinner", { attrs: { color: "primary" } })],
                    1
                  )
                : _c(
                    "CCardBody",
                    [
                      _c(
                        "CMedia",
                        {
                          attrs: {
                            "aside-image-props": {
                              width: 128,
                              height: 128,
                              src: _vm.user.image_icon || _vm.user.gravatar,
                              shape: "rounded-circle",
                            },
                          },
                        },
                        [
                          _c(
                            "CRow",
                            { staticClass: "ml-2" },
                            [
                              _c(
                                "CCol",
                                {
                                  staticClass: "widget",
                                  attrs: { col: "12", sm: "6", lg: "4" },
                                },
                                [
                                  _c(
                                    "CWidgetIcon",
                                    {
                                      attrs: {
                                        header: "Orders",
                                        text: String(_vm.user.orders_count),
                                        color: "gradient-dark",
                                        "icon-padding": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "footer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "CCardFooter",
                                                { staticClass: "px-3 py-2" },
                                                [
                                                  typeof _vm.user.id !==
                                                  "undefined"
                                                    ? _c(
                                                        "CLink",
                                                        {
                                                          staticClass:
                                                            "font-sm text-muted d-flex justify-content-between",
                                                          attrs: {
                                                            to: {
                                                              name: "UserOrderList",
                                                              params: {
                                                                user_id:
                                                                  _vm.user.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " View Orders "
                                                          ),
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name: "cil-arrow-right",
                                                              width: "16",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: {
                                          name: "cil-dinner",
                                          width: "24",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "widget",
                                  attrs: { col: "12", sm: "6", lg: "4" },
                                },
                                [
                                  _c(
                                    "CWidgetIcon",
                                    {
                                      attrs: {
                                        header: "Reviews",
                                        text: String(_vm.user.reviews_count),
                                        color: "gradient-success",
                                        "icon-padding": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "footer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "CCardFooter",
                                                { staticClass: "px-3 py-2" },
                                                [
                                                  typeof _vm.user.id !==
                                                  "undefined"
                                                    ? _c(
                                                        "CLink",
                                                        {
                                                          staticClass:
                                                            "font-sm text-muted d-flex justify-content-between",
                                                          attrs: {
                                                            to: {
                                                              name: "UserReviews",
                                                              params: {
                                                                user_id:
                                                                  _vm.user.id,
                                                              },
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " View Reviews "
                                                          ),
                                                          _c("CIcon", {
                                                            attrs: {
                                                              name: "cil-arrow-right",
                                                              width: "16",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: {
                                          name: "cil-star",
                                          width: "24",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "CCol",
                                {
                                  staticClass: "widget",
                                  attrs: { col: "12", sm: "6", lg: "4" },
                                },
                                [
                                  _c(
                                    "CWidgetIcon",
                                    {
                                      attrs: {
                                        header: "Addresses",
                                        text: String(_vm.user.addresses.length),
                                        color: "gradient-warning",
                                        "icon-padding": false,
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "footer",
                                          fn: function () {
                                            return [
                                              _c(
                                                "CCardFooter",
                                                { staticClass: "px-3 py-2" },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "smooth-scroll",
                                                          rawName:
                                                            "v-smooth-scroll",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "font-sm text-muted d-flex justify-content-between",
                                                      attrs: {
                                                        href: "#user-addresses",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Addresses "
                                                      ),
                                                      _c("CIcon", {
                                                        attrs: {
                                                          name: "cil-arrow-right",
                                                          width: "16",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c("CIcon", {
                                        attrs: {
                                          name: "cil-location-pin",
                                          width: "24",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("h5", { staticClass: "my-3" }, [
                        _vm._v("User Details"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "d-inline-flex flex-wrap w-100" },
                        [
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Full Name:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.first_name))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("ID#:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.id))]),
                          ]),
                          _c(
                            "div",
                            { staticClass: "item-group" },
                            [
                              _c("label", [_vm._v("Language:")]),
                              _vm.user.lang == "en"
                                ? _c("CIcon", {
                                    staticClass: "flag",
                                    attrs: {
                                      height: 14,
                                      content: _vm.$options.flagSet["cifGb"],
                                    },
                                  })
                                : _c("CIcon", {
                                    staticClass: "flag",
                                    attrs: {
                                      height: 14,
                                      content:
                                        _vm.$options.flagSet[
                                          "cif" +
                                            _vm.user.lang
                                              .charAt(0)
                                              .toUpperCase() +
                                            _vm.user.lang.slice(1).toLowerCase()
                                        ],
                                    },
                                  }),
                              _c("span", { staticClass: "ml-2" }, [
                                _vm._v(_vm._s(_vm.user.lang.toUpperCase())),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "item-group" },
                            [
                              _c("label", [_vm._v("Country:")]),
                              _vm.user.country
                                ? [
                                    _c("CIcon", {
                                      staticClass: "flag",
                                      attrs: {
                                        height: 14,
                                        content:
                                          _vm.$options.flagSet[
                                            "cif" +
                                              _vm.user.country
                                                .charAt(0)
                                                .toUpperCase() +
                                              _vm.user.country
                                                .slice(1)
                                                .toLowerCase()
                                          ],
                                      },
                                    }),
                                    _c("span", { staticClass: "ml-2" }, [
                                      _vm._v(
                                        _vm._s(_vm.user.country.toUpperCase())
                                      ),
                                    ]),
                                  ]
                                : _c("span", [_vm._v("-")]),
                            ],
                            2
                          ),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("User Type:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.usertype))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Type:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.type))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Mobile:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.mobile))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("2FA Mobile:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.tfamobile))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Gender:")]),
                            _c("span", { staticClass: "gender" }, [
                              _vm._v(_vm._s(_vm.user.gender == 1 ? "♂" : "♀")),
                            ]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Email:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.email))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Email Verified At:")]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.user.email_verified_at)),
                            ]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Email Subscribe:")]),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.user.email_subscribe == true
                                    ? "YES"
                                    : "NO"
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Facebook ID#:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.fb_id))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Google ID#:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.google_id))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Apple ID#:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.apple_id))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("IP:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.ip))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Last Login:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.last_login))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Created At:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.created_at))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Updated At:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.updated_at))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Address:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.address))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("City:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.city))]),
                          ]),
                          _c("div", { staticClass: "item-group" }, [
                            _c("label", [_vm._v("Postal Code:")]),
                            _c("span", [_vm._v(_vm._s(_vm.user.postal_code))]),
                          ]),
                        ]
                      ),
                      _c(
                        "CCard",
                        {
                          staticClass: "mt-3",
                          attrs: { id: "user-addresses" },
                        },
                        [
                          _c(
                            "CCardHeader",
                            [
                              _c("CIcon", {
                                staticClass: "align-bottom",
                                attrs: { name: "cil-location-pin" },
                              }),
                              _c("strong", [_vm._v(" Addresses ")]),
                              _c("small", [
                                _vm._v(
                                  "(" + _vm._s(_vm.user.addresses.length) + ")"
                                ),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "CCardBody",
                            [
                              _c(
                                "CListGroup",
                                { attrs: { accent: "" } },
                                _vm._l(_vm.user.addresses, function (address) {
                                  return _c(
                                    "CListGroupItem",
                                    {
                                      key: address.id,
                                      attrs: { accent: "warning" },
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(_vm._s(address.maPostalcode)),
                                      ]),
                                      _vm._v(", " + _vm._s(address.maCity)),
                                      _c("br"),
                                      _vm._v(
                                        " " + _vm._s(address.maAddress) + " "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }